import i18n from '@/lang/lang'

export default {

    required: (value) => !!value || i18n.t('rules.required'),

    minLength8: (v) => (v && v.length >= 8) || i18n.t('rules.minCharacter'),

    validEmail: function (email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return !email || re.test(email?.trim()) || i18n.t('rules.emailFormat')
    },

    validPhoneOrEmpty: (phone) =>
        !phone ||
  /^(\+|\d)[0-9]{7,16}$/.test(phone) ||
      i18n.t('rules.phoneNumber'),

    validPassword: (pass) => (pass && pass.length >= 4) || i18n.t("rules.password"),
    /* validPassword: (pass) =>
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z*#.!@$%^&\(\)\{\}\[\]:;<>,.?\/~_+-=|\\]{8,}$/.test(pass) ||
      i18n.t("rules.password"), */
    // "password": "La contraseña debería contener al menos 1 dígito, 1 minúscula, 1 mayúscula y mínimo 8 caracteres",

    validNumber: (number) =>
        /^(\+|\d)[0-9]{0,}$/.test(number) || i18n.t("rules.number"),

    validNumberOrEmpty: (number) =>
        !number ||
        /^(\+|\d)[0-9]{0,}$/.test(number) || i18n.t("rules.number"),


    requiredAtLeastOne: (v) => v.length !== 0 || i18n.t("rules.required"),

    validWeb: (web) =>
        !web ||
    /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/.test(
        web
    ) ||
      i18n.t("rules.web"),

    validDecimalNumber: (number) =>
        /^[+-]?\d+(\.\d+)?$/.test(number) ||
      i18n.t("rules.decimalNumber")

    /* passwordMatch: () =>
        this.password.value == this.password_confirmation.value ||
    'Password confirmation don\'t match', */
}
